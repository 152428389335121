import React, {Fragment, lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {CollapseExpand, InfoTip} from '@ideascale/ui';
import {goTo} from '@ideascale/commons/dist/utils/CommonUtil';
import {Member} from '@ideascale/commons/dist/models/Member';
import {TobBarLogo} from '@ideascale/commons/dist/components/topbar/topbar-logo/TobBarLogo';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import iconPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import ideascaleLogo from '@ideascale/ui/dist/assets/ideascale-logo-blue.svg';
import {useSidebarContext} from 'contexts/SidebarContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {useCommunityService} from 'hooks/useService';
import {useSidebarService} from './useSidebarService';
import {appLinks} from 'services/AppLinks';
import {externalLinks} from 'constants/AppConstants';
import {useCampaignModuleService} from 'hooks/useCampaignModuleService';
import {useClassificationService} from 'hooks/useClassificationService';
import {SidebarSkeleton} from 'components/sidebar/common/SidebarSkeleton';
import {PageParameters} from 'models/types/PageParameters';
import {SidebarItem} from 'models/sidebar/SidebarItem';
import {SidebarItemKey} from 'models/enums/SidebarItemKey';
import {AdminModeratorData} from 'models/AdminModeratorData';
import {PagedResponseContent} from 'models/PagedResponseContent';

const ArchiveCampaignsModule = lazy(() => import('components/sidebar/common/ArchiveCampaignsModule').then(module => ({default: module.ArchiveCampaignsModule})));
const Kudos = lazy(() => import('components/sidebar/Kudos').then(module => ({default: module.Kudos})));
const ActiveCampaignsModule = lazy(() => import('components/sidebar/common/ActiveCampaignsModule').then(module => ({default: module.ActiveCampaignsModule})));
const TagsModule = lazy(() => import('components/sidebar/common/TagsModule').then(module => ({default: module.TagsModule})));
const SocialShare = lazy(() => import('components/sidebar/SocialShare').then(module => ({default: module.SocialShare})));
const SocialSubscribe = lazy(() => import('components/sidebar/SocialSubscribe').then(module => ({default: module.SocialSubscribe})));
const ListModule = lazy(() => import('components/sidebar/common/ListModule').then(module => ({default: module.ListModule})));
const SidebarLeaderboard = lazy(() => import('components/sidebar/common/SidebarLeaderboard').then(module => ({default: module.SidebarLeaderboard})));
const StaticContentWidget = lazy(() => import('components/sidebar/StaticContentWidget').then(module => ({default: module.StaticContentWidget})));
const CustomWidget = lazy(() => import('components/sidebar/CustomWidget').then(module => ({default: module.CustomWidget})));

const ADMIN_MODULE_ID = 'admin-sidebar-module';
const MODERATOR_MODULE_ID = 'moderator-sidebar-module';

export const CommunitySidebarContainer = () => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const {activeCampaignsHolder, archivedCampaignsHolder} = useSidebarContext();
    const [dataFetching, setDataFetching] = useState<boolean>(false);
    const [sidebarItems, setSidebarItems] = useState<SidebarItem[]>([]);
    const {fetchClassifications} = useClassificationService();
    const sidebarItemsRef = useRef<{ [key: string]: JSX.Element }>({});
    const {
        fetchSidebarActiveCampaignModule,
        fetchActiveCampaignGroups,
        fetchActiveCampaignsByGroup,
        fetchSidebarArchivedCampaignModule,
        fetchArchivedCampaignGroups,
        fetchArchivedCampaignsByGroup,
        fetchSidebarCampaignSummary,
    } = useCampaignModuleService();

    const {
        fetchSidebarTags,
        fetchSocialSubscription,
        fetchSidebarSocialShare,
        addSidebarComponent,
        fetchSidebarContactWidget,
        fetchKudos,
        fetchSidebarLeaderboardItem,
        fetchCommunitySummary
    } = useSidebarService();

    const fetchAdministrators = useCallback(async (data: PageParameters): Promise<AdminModeratorData> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarAdmins(data);
        }
        return AdminModeratorData.EMPTY;
    }, [communityService]);

    const fetchGlobalModerators = useCallback(async (data: PageParameters): Promise<AdminModeratorData> => {
        if (communityService !== null) {
            return await communityService.fetchSidebarGlobalModerators(data);
        }
        return AdminModeratorData.EMPTY;
    }, [communityService]);

    const fetchCommunitySidebarItemsData = useCallback(async () => {
        if (communityService !== null) {
            return await communityService.fetchCommunitySidebarItems();
        }
        return [SidebarItem.EMPTY];
    }, [communityService]);

    const fetchCommunitySidebarItems = useCallback(async () => {
        try {
            setDataFetching(true);
            const response = await fetchCommunitySidebarItemsData();
            setSidebarItems(response);
        } catch (error: any) {
            handleErrorResponse(error);
        } finally {
            setDataFetching(false);
        }
    }, [fetchCommunitySidebarItemsData, handleErrorResponse]);

    const fetchRecipients = useCallback(async (pageParameters: PageParameters): Promise<PagedResponseContent<Member>> => {
        if (communityService !== null) {
            return await communityService.fetchRecipients(pageParameters);
        }
        return PagedResponseContent.nullObject<Member>();
    }, [communityService]);

    sidebarItemsRef.current = useMemo(() => {
        const components: { [key: string]: JSX.Element } = {};
        if (!dataFetching) {
            addSidebarComponent(
                components,
                SidebarItemKey.ACTIVE_CAMPAIGNS,
                <Suspense>
                    <ActiveCampaignsModule
                        headerTitle={localizer.msg('sidebar.campaign.active-campaigns')}
                        config={{
                            allIdeas: true,
                            manageCampaign: true,
                            defaultExpand: true
                        }}
                        dataFetchingType="EAGER"
                        addCampaigns={activeCampaignsHolder.addCampaigns}
                        updateCampaignGroupPaginationData={activeCampaignsHolder.updateCampaignGroupPaginationData}
                        fetchSidebarCampaignModule={fetchSidebarActiveCampaignModule}
                        fetchCampaignGroups={fetchActiveCampaignGroups}
                        fetchCampaignsByGroup={fetchActiveCampaignsByGroup}
                        fetchSidebarCampaignSummary={fetchSidebarCampaignSummary}
                        updateCampaignGroupExpansion={activeCampaignsHolder.updateCampaignGroupExpansion}
                    />
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.COMMUNITY_TAGS,
                <CollapseExpand
                    containerClass="community-tags panel-light"
                    headerTitle={localizer.msg('sidebar.tags.community-tags')}
                    svgIconSprite={iconPath}
                    defaultOpen={true}>
                    <Suspense>
                        <TagsModule fetchTags={fetchSidebarTags} manageLink={'/tags'}/>
                    </Suspense>
                </CollapseExpand>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.KUDOS_TICKER,
                <CollapseExpand
                    containerClass="kudos-module panel-light"
                    headerTitle={localizer.msg('sidebar.kudos.community-kudos')}
                    svgIconSprite={iconPath}
                    infoTip={
                        <InfoTip id="kudos-infoTip"
                                 placement="top"
                                 instructions={localizer.msg('kudos.info-tip-instructions')}
                                 content={localizer.msg('info-tip.kudos-sidebar-info')}/>
                    }
                    defaultOpen={true}>
                    <Suspense>
                        <Kudos fetchKudos={fetchKudos}/>
                    </Suspense>
                </CollapseExpand>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.LEADERBOARD,
                <CollapseExpand
                    containerClass="leaderboard panel-light"
                    headerClass="tour-lucido_leaderboard"
                    headerTitle={localizer.msg('sidebar.leaderboard.community-leaderboard')}
                    svgIconSprite={iconPath}
                    defaultOpen={true}>
                    <Suspense>
                        <SidebarLeaderboard fetchSidebarLeaderboardItem={fetchSidebarLeaderboardItem}/>
                    </Suspense>
                </CollapseExpand>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.ARCHIVED_CAMPAIGNS,
                <Suspense>
                    <ArchiveCampaignsModule
                        headerTitle={localizer.msg('sidebar.campaign.archived-campaigns')}
                        config={{allIdeas: false, manageCampaign: false, defaultExpand: true}}
                        dataFetchingType="EAGER"
                        addCampaigns={archivedCampaignsHolder.addCampaigns}
                        updateCampaignGroupPaginationData={archivedCampaignsHolder.updateCampaignGroupPaginationData}
                        fetchSidebarCampaignModule={fetchSidebarArchivedCampaignModule}
                        fetchCampaignGroups={fetchArchivedCampaignGroups}
                        fetchCampaignsByGroup={fetchArchivedCampaignsByGroup}
                        fetchSidebarCampaignSummary={fetchSidebarCampaignSummary}
                        updateCampaignGroupExpansion={archivedCampaignsHolder.updateCampaignGroupExpansion}
                    />
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.SOCIAL_SHARE,
                <CollapseExpand containerClass="social-web panel-light"
                                headerTitle={localizer.msg('sidebar.social.social-web')}
                                svgIconSprite={iconPath}
                                defaultOpen={true}>
                    <Suspense>
                        <SocialShare fetchClassifications={fetchClassifications}
                                     fetchSidebarSocialShare={fetchSidebarSocialShare}
                                     fetchRecipients={fetchRecipients}/>
                    </Suspense>
                </CollapseExpand>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.SOCIAL_SUBSCRIBE,
                <Suspense>
                    <SocialSubscribe localizer={localizer} fetchSocialSubscription={fetchSocialSubscription}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.MODERATORS,
                <Suspense>
                    <ListModule
                        inputId={MODERATOR_MODULE_ID} headerTitle={localizer.msg('sidebar.moderators.header-title')}
                        fetchMemberList={fetchGlobalModerators}
                        manageLink={appLinks.globalModeratorSettings}
                        manageText={localizer.msg('sidebar.moderators.manage-global')}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.ADMINISTRATORS,
                <Suspense>
                    <ListModule inputId={ADMIN_MODULE_ID}
                                headerTitle={localizer.msg('sidebar.administrators.header-title')}
                                fetchMemberList={fetchAdministrators}
                                manageLink={appLinks.adminSettings}
                                manageText={localizer.msg('sidebar.administrators.manage')}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.CONTACT,
                <Suspense>
                    <CustomWidget containerClass={'contact'} fetchCustomWidgetData={fetchSidebarContactWidget}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.COMMUNITY_SUMMARY,
                <Suspense>
                    <CustomWidget containerClass={'community-summary'} fetchCustomWidgetData={fetchCommunitySummary}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.ONLINE_LEARNING,
                <Suspense>
                    <StaticContentWidget
                        heading={localizer.msg('sidebar.static-content.online-learning.heading')}
                        title={localizer.msg('sidebar.static-content.online-learning.title')}
                        content={localizer.msg('sidebar.static-content.online-learning.content')}
                        button={{
                            label: localizer.msg('sidebar.static-content.online-learning.action'),
                            external: true,
                            onClick: () => goTo(externalLinks.onlineLearning(), '_blank')
                        }}/>
                </Suspense>
            );

            addSidebarComponent(
                components,
                SidebarItemKey.ARTICLES_RESOURCES,
                <Suspense>
                    <StaticContentWidget
                        heading={localizer.msg('sidebar.static-content.articles-and-resources.heading')}
                        title={localizer.msg('sidebar.static-content.articles-and-resources.title')}
                        content={localizer.msg('sidebar.static-content.articles-and-resources.content')}
                        button={{
                            label: localizer.msg('sidebar.static-content.articles-and-resources.action'),
                            external: true,
                            onClick: () => goTo(externalLinks.articlesResources(), '_blank')
                        }}/>
                </Suspense>
            );
        }
        return components;
    }, [dataFetching, addSidebarComponent, localizer, activeCampaignsHolder.addCampaigns, activeCampaignsHolder.updateCampaignGroupPaginationData, activeCampaignsHolder.updateCampaignGroupExpansion, fetchSidebarActiveCampaignModule, fetchActiveCampaignGroups, fetchActiveCampaignsByGroup, fetchSidebarCampaignSummary, fetchSidebarTags, fetchKudos, fetchSidebarLeaderboardItem, archivedCampaignsHolder.addCampaigns, archivedCampaignsHolder.updateCampaignGroupPaginationData, archivedCampaignsHolder.updateCampaignGroupExpansion, fetchSidebarArchivedCampaignModule, fetchArchivedCampaignGroups, fetchArchivedCampaignsByGroup, fetchClassifications, fetchSidebarSocialShare, fetchRecipients, fetchSocialSubscription, fetchGlobalModerators, fetchAdministrators, fetchSidebarContactWidget, fetchCommunitySummary]);

    useEffect(() => {
        fetchCommunitySidebarItems().then();
    }, [fetchCommunitySidebarItems]);

    const renderSidebarComponents = useCallback(() => {
        return (
            sidebarItems.filter(item => item.visible).map((sidebarItem, index) => (
                <Fragment key={index}>
                    {sidebarItemsRef.current[sidebarItem.key]}
                </Fragment>
            ))
        );
    }, [sidebarItems]);

    return (
        <Fragment>
            {
                dataFetching
                    ? <SidebarSkeleton/>
                    : (
                        <div className={`sidebar-view-mode`}>
                            {
                                renderSidebarComponents()
                            }
                        </div>
                    )
            }
            {
                !dataFetching &&
                <div className="footer-ideascale-logo mt-4 mb-2 d-none d-lg-block">
                    <a href="https://ideascale.com" title="Go to ideascale.com">
                        <TobBarLogo logo={ideascaleLogo} alt="Powered by IdeaScale" className="w-100"/>
                    </a>
                </div>
            }
        </Fragment>
    );
};
