import {Theme} from '@ideascale/ui';
import {CommentType} from '@ideascale/commons/dist/models/enums/CommentType';
import {IdeaListMode} from 'models/enums/IdeaListMode';
import {OrderBy} from 'models/enums/OrderBy';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {MyIdeaListMode} from 'models/enums/MyIdeaListMode';
import {TimePeriod} from 'models/enums/TimePeriod';

export const serviceLinks = {
    token: () => '/get-token',
    localizations: (lang: string) => `/localizations/${lang}`,
    communityConfig: () => '/community/config',
    topBar: () => '/topbar',
    tosTopBar: () => '/topbar/community-tos',
    ideaListViewMode: (viewMode: IdeaListViewMode) => `/idea-list-view-mode/${viewMode}`,
    fileUpload: () => '/upload?fileType=GENERIC',
    imageUpload: () => '/upload?fileType=IMAGE',
    tempImageUpload: () => '/community/api/upload?fileType=IMAGE',
    topBarCampaigns: () => '/topbar/campaigns',
    topBarCustomPages: () => '/topbar/custom-pages',
    topBarLabels: () => '/topbar/labels',
    topBarMessages: () => '/topbar/community/messages',
    topbarActionItems: () => '/topbar/action-items',
    slider: () => '/slider',
    subscribeCampaign: (campaignId: number) => `/subscribe/campaign/${campaignId}`,
    unsubscribeCampaign: (campaignId: number) => `/unsubscribe/campaign/${campaignId}`,
    topbarNotifications: (groupId: number) => `/topbar/in-app-notifications${groupId > 0 ? ('/group/' + groupId) : ''}`,
    followTag: (name: string) => `/follow/tag/${name}`,
    unfollowTag: (name: string) => `/unfollow/tag/${name}`,
    fetchFollowingTag: (name: string) => `/follows/tag/${name}`,
    fetchCampaignFollows: (campaignId: number) => `/follows/campaign/${campaignId}`,
    fetchCampaignFollowers: (campaignId: number) => `follows/campaign/${campaignId}/followers`,
    ideas: (campaignId: 'active' | 'expired' | string = 'active', stageKey: string = 'all', mode: IdeaListMode | 'unspecified' = 'unspecified', order: OrderBy = OrderBy.DESC) => `/campaigns/${campaignId}/stages/${stageKey}/ideas/${mode}/${order}`,
    fetchTags: () => '/tags',
    fetchModeratorTags: () => '/moderator-tags',
    fetchSocialSubscription: () => '/sidebar/social-subscription',
    fetchSidebarAdmins: () => '/sidebar/administrators',
    fetchSidebarGlobalModerators: () => '/sidebar/moderators/global',
    fetchSidebarCampaignModerators: (campaignId: number) => `/sidebar/moderators/campaign/${campaignId}`,
    activeCampaignGroups: () => '/active/campaign-groups',
    activeCampaignsByGroup: (groupId: number) => `/active/campaigns/campaign-group/${groupId}`,
    sidebarActiveCampaignModule: () => `/sidebar/active/campaign-module-content`,
    sidebarCampaignSummary: (id: number) => `/sidebar/campaign/${id}/content`,
    archivedCampaignGroups: () => '/archived/campaign-groups',
    archivedCampaignsByGroup: (groupId: number) => `/archived/campaigns/campaign-group/${groupId}`,
    sidebarArchivedCampaignModule: () => `/sidebar/archived/campaign-module-content`,
    fetchKudos: () => '/sidebar/community-kudos',
    fetchKudosMembers: () => '/kudos/search/member',
    recipientsByCampaign: (campaignId: number) => `/campaign/${campaignId}/search-email-recipients`,
    assignKudosToMember: (kudoTypeId: number, memberId: number) => `/kudos/give/special/${kudoTypeId}/member/${memberId}`,
    fetchCampaignActivity: (campaignId: number) => `/sidebar/campaign-activity/campaign/${campaignId}`,
    campaignSummary: (id: number) => `/campaign/${id}/summary`,
    fetchSidebarTags: () => '/sidebar/tags',
    emptyFormFields: () => `/idea-form`,
    campaignFormFields: (campaignId: number) => `/idea-form/campaign/${campaignId}`,
    ideaFormFields: (ideaId: number) => `/idea-form/idea/${ideaId}`,
    copyIdeaFormFields: (ideaId: number) => `/idea-form/copy-idea/${ideaId}`,
    createIdea: () => '/create/idea',
    modifyIdea: (ideaId: number) => `/update/idea/${ideaId}`,
    atMentionMembers: () => '/member/search/at-mention',
    similarIdeaSearch: (ideaId: number) => `/idea/${ideaId}/search-similar`,
    similarIdeaSearchByKeyword: (ideaId: number) => `/idea/${ideaId}/search-similar-keyword`,
    allowedGroupedCampaigns: () => '/idea-form/campaigns',
    drafts: () => '/idea-form/drafts',
    deleteDraft: (ideaId: number) => `/draft/${ideaId}/delete`,
    ideaDetails: (ideaId: number) => `/idea/${ideaId}/detail`,
    linkIdeas: (ideaId: number) => `idea/${ideaId}/link-idea`,
    unlinkIdea: (ideaId: number) => `idea/${ideaId}/unlink-idea`,
    superComments: (ideaId: number) => `/idea/${ideaId}/super-comments`,
    deleteSuperComment: (commentId: number, commentType: CommentType) => `/comment/${commentId}/type/${commentType}/remove-super-comment`,
    reorderSuperComments: (ideaId: number) => `/idea/${ideaId}/reorder-super-comments`,
    ideaOnBehalfOfSearch: () => '/member/search/on-behalf-of',
    ideaContributorSearch: () => '/member/search/contributor',
    ideaFormLabels: (ideaId?: number) => ideaId ? `/idea-form/labels/idea/${ideaId}` : '/idea-form/labels',
    detectLanguage: () => '/detect-language',
    searchTags: () => '/search/tags',
    searchModeratorTags: () => '/search/moderator-tags',
    saveTags: (ideaId: number) => `/idea/${ideaId}/save-tags`,
    saveModeratorTags: (ideaId: number) => `/idea/${ideaId}/save-moderator-tags`,
    suggestedTags: () => '/suggested/tags',
    sidebarStages: (campaignId: number) => `/sidebar/stages/campaign/${campaignId}`,
    communitySidebar: () => `/sidebar/get`,
    campaignSidebar: (campaignId: number) => `/sidebar/campaign/${campaignId}/get`,
    fetchCampaignDetails: (campaignId: number) => `/campaign/${campaignId}/detail`,
    fetchCampaignHeader: (campaignId: number) => `/campaign/${campaignId}/header`,
    fetchCampaignTeamMembers: (campaignId: number) => `/campaign/${campaignId}/team`,
    fetchOwnedIdeas: (memberId: number) => `/profile/owned-ideas/member/${memberId}`,
    fetchCommunityMemberships: (memberId: number) => `/profile/community-memberships/member/${memberId}`,
    memberProfileFields: (memberId: number) => `/profile/${memberId}/fields`,
    updateMemberProfileFields: (memberId: number) => `/profile/${memberId}/save-fields`,
    memberSubscription: (memberId: number, follow: boolean) => `/profile/member/${memberId}/${follow ? 'follow' : 'unfollow'}`,
    sidebarProfile: (memberId?: number) => memberId ? `/sidebar/profile/${memberId}` : '/sidebar/profile',
    updateProfileAboutMeSection: () => '/sidebar/profile/save-profile',
    activityStream: (memberId: number, type: string) => `/profile/activity-stream/member/${memberId}${type ? `/activity-type/${type}` : ''}`,
    profileNotifications: (groupId: number) => `/profile/in-app-notifications${groupId > 0 ? ('/group/' + groupId) : ''}`,
    markNotificationsAsSeen: () => '/profile/in-app-notifications/mark-all-as-seen',
    fetchIdeaModerateActions: (ideaId: number) => `/idea/${ideaId}/moderation-actions`,
    fetchIdeaMoreActions: (ideaId: number) => `/idea/${ideaId}/member-actions`,
    followIdea: (ideaId: number) => `/idea/${ideaId}/follow`,
    unfollowIdea: (ideaId: number) => `/idea/${ideaId}/unfollow`,
    enableCommenting: (ideaId: number) => `/idea/${ideaId}/enable-commenting`,
    disableCommenting: (ideaId: number) => `/idea/${ideaId}/disable-commenting`,
    changeStage: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/change`,
    fetchMembersToAssignOwner: (ideaId: number) => `/idea/${ideaId}/search-owners`,
    fetchIdeaAssignedOwners: (ideaId: number) => `idea/${ideaId}/assigned-owners`,
    assignIdeaOwners: (ideaId: number) => `idea/${ideaId}/assign-owner`,
    deleteIdea: (ideaId: number) => `/idea/${ideaId}/delete`,
    vote: (ideaId: number) => `idea/${ideaId}/vote`,
    retractVote: (ideaId: number) => `idea/${ideaId}/vote/retract`,
    fetchChangeCampaigns: () => `/campaign/change/grouped-campaigns`,
    campaignStages: (ideaId: number, campaignId: number) => `/idea/${ideaId}/campaign/${campaignId}/stages`,
    changeCampaign: (ideaId: number, campaignId: number, stageId?: number, isDifferentStage: boolean = false) => isDifferentStage ? `/idea/${ideaId}/campaign/${campaignId}/stage/${stageId}/change` : `/idea/${ideaId}/campaign/${campaignId}/change`,
    ideaKudo: (ideaId: number) => `/idea/${ideaId}/give-kudo`,
    fetchIdeaEmailRecipients: (ideaId: number) => `/idea/${ideaId}/search-email-recipients`,
    emailIdeaByRecipientEmails: (ideaId: number) => `/idea/${ideaId}/send-email-by-recipient-emails`,
    emailIdeaByRecipientIds: (ideaId: number) => `/idea/${ideaId}/send-email-by-recipient-ids`,
    digestEmailByRecipientEmails: (campaignId: number) => `/in-app-notifications/campaign-performance/${campaignId}/share-via-email`,
    digestEmailByRecipientIds: (campaignId: number) => `/in-app-notifications/campaign-performance/${campaignId}/share-via-recipient-ids`,
    emailIdeaAuthor: (ideaId: number) => `/idea/${ideaId}/send-email-to-author`,
    fetchIdeaAuthorEmails: (ideaId: number) => `/idea/${ideaId}/author-email`,
    banMember: (memberId: number) => `/member/${memberId}/ban`,
    followMember: (memberId: number) => `profile/member/${memberId}/follow`,
    unfollowMember: (memberId: number) => `profile/member/${memberId}/unfollow`,
    pinIdea: (ideaId: number) => `idea/${ideaId}/pin-idea`,
    unpinIdea: (ideaId: number) => `idea/${ideaId}/unpin-idea`,
    updateLabels: (ideaId: number) => `/idea/${ideaId}/label/update`,
    reportDuplicate: (ideaId: number) => `/idea/${ideaId}/report-duplicate`,
    reportAbuse: (ideaId: number) => `/idea/${ideaId}/report-abuse`,
    myIdeas: (mode: MyIdeaListMode = MyIdeaListMode.MY_IDEAS, sortBy: IdeaListMode = IdeaListMode.TRENDING, stageKey: string = 'all', order: OrderBy = OrderBy.DESC) => `/profile/ideas/${mode}/sort/${sortBy}/stage/${stageKey}/${order}`,
    moveIdea: (ideaId: number, campaignId: number, stageId?: number, isDifferentStage: boolean = false) => isDifferentStage ? `/idea/${ideaId}/campaign/${campaignId}/stage/${stageId}/change` : `/idea/${ideaId}/campaign/${campaignId}/change`,
    giveKudo: (ideaId: number) => `/idea/${ideaId}/give-kudo`,
    fetchSidebarSocialShare: () => '/sidebar/sharing-media',
    recipients: () => `/profile/messages/recipients`,
    inboxMessages: () => `/profile/messages/inbox`,
    sentMessages: () => `/profile/messages/sent`,
    memberSendMessages: () => `/profile/messages/send-message`,
    trashConversationThread: (conversationThreadId: number) => `/profile/messages/conversation-thread/${conversationThreadId}/trash`,
    conversationThread: (conversationThreadId: number) => `/profile/messages/conversation-thread/${conversationThreadId}/detail`,
    conversationThreadReply: (conversationThreadId: number) => `/profile/messages/conversation-thread/${conversationThreadId}/reply`,
    countUnread: () => `/profile/messages/count-unread`,
    fetchFollowedMembers: (memberId: number) => `/profile/follows/member/${memberId}/members`,
    fetchFollowedIdeas: (memberId: number) => `/profile/follows/member/${memberId}/ideas`,
    fetchFollowedTags: (memberId: number) => `/profile/follows/member/${memberId}/tags`,
    fetchFollowedCampaignGroups: (memberId: number) => `/profile/follows/member/${memberId}/campaign-groups`,
    fetchFollowedCampaignByGroupId: (memberId: number, groupId: number) => `/profile/follows/member/${memberId}/campaign-group/${groupId}/campaigns`,
    fetchRevisionHistory: (ideaId: number) => `/idea/${ideaId}/revision-history`,
    originalIdea: (ideaId: number) => `/idea/${ideaId}/original-idea`,
    ideaAttachments: (ideaId: number) => `/idea/${ideaId}/attachments`,
    deleteIdeaAttachment: (ideaId: number, fileId: number) => `/idea/${ideaId}/file/${fileId}/delete`,
    deleteIdeaFieldAttachment: (ideaId: number) => `/idea/${ideaId}/delete-idea-field-attachment`,
    deleteIdeaStageAttachment: (ideaId: number) => `/idea/${ideaId}/delete-stage-field-attachment`,
    saveIdeaAttachments: (ideaId: number) => `/idea/${ideaId}/save-attachments`,
    ideaFollowers: (ideaId: number) => `/idea/${ideaId}/followers`,
    ideaOwners: (ideaId: number) => `/idea/${ideaId}/owners`,
    fundTarget: (ideaId: number) => `/idea/${ideaId}/modify-fund-target`,
    modifyFundPledge: (ideaId: number) => `/idea/${ideaId}/modify-fund-pledge`,
    editFundPledge: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/edit-fund-pledge`,
    ideaActivities: (ideaId: number) => `/idea/${ideaId}/activities`,
    ideaStageActivity: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/activity`,
    assessmentEditRank: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/assessment-edit-rank`,
    saveAssessmentRank: (ideaId: number, assessmentId: number, questionId: number) => `/idea/${ideaId}/assessment/${assessmentId}/question/${questionId}/save-rank`,
    stageFieldRevisionHistory: (ideaId: number, fieldId: number) => `/idea/${ideaId}/stage-field/${fieldId}/revision-history`,
    saveStageField: (ideaId: number, fieldId: number) => `/idea/${ideaId}/stage-field/${fieldId}`,
    teamRole: () => `/team-roles`,
    teamMember: () => `/member/search/team-members`,
    assignTeamRole: (ideaId: number, stageId: number, positionId: number, teamRoleId: number) => `/idea/${ideaId}/stage/${stageId}/position/${positionId}/team-role/${teamRoleId}/change`,
    joinBuildTeam: (ideaId: number, stageId: number, positionId: number) => `/idea/${ideaId}/stage/${stageId}/position/${positionId}/join-team`,
    assignMemberBuildTeam: (ideaId: number, stageId: number, positionId: number, memberId: number) => `/idea/${ideaId}/stage/${stageId}/position/${positionId}/member/${memberId}/assign`,
    unassignMemberBuildTeam: (ideaId: number, stageId: number, memberId: number) => `/idea/${ideaId}/stage/${stageId}/member/${memberId}/unassign`,
    promoteToOwnerBuildTeam: (ideaId: number, stageId: number, memberId: number) => `/idea/${ideaId}/stage/${stageId}/member/${memberId}/promote-owner`,
    addPositionBuildTeam: (ideaId: number, stageId: number, buildTeamGroupId: number) => `/idea/${ideaId}/stage/${stageId}/build-team-group/${buildTeamGroupId}/add-position`,
    removePositionBuildTeam: (ideaId: number, stageId: number, positionId: number) => `/idea/${ideaId}/stage/${stageId}/position/${positionId}/remove-position`,
    campaignTosContent: (campaignId: number) => `/campaign/${campaignId}/tos`,
    campaignTosAccept: (campaignId: number) => `/campaign/${campaignId}/accept-tos`,
    unfurlUrl: () => '/community/unfurl-url',
    reviewNotes: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/reviewscale-notes`,
    reviewScaleEditRank: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/reviewscale-edit-rank`,
    rankValueFactor: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/rank-value-factor`,
    rankCostFactor: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/rank-cost-factor`,
    rankConstraintFactor: (ideaId: number, stageId: number) => `/idea/${ideaId}/stage/${stageId}/rank-constraint-factor`,
    saveEstimate: (ideaId: number, stageId: number) => `/idea/${ideaId}/save-estimate/${stageId}`,
    ideaLabels: (ideaId: number) => `/idea/${ideaId}/labels`,
    commentsList: (ideaId: number) => `/comment/idea/${ideaId}/list`,
    commentsListById: (commentId: number) => `/comment/${commentId}/comments-page`,
    stageCommentList: (ideaId: number, stageId: number) => `/stage-comment/idea/${ideaId}/stage/${stageId}/all`,
    childCommentsList: (commentId: number) => `/comment/${commentId}/child-comments`,
    pinnedComment: (ideaId: number) => `/comment/idea/${ideaId}/pinned`,
    commentPermissions: (parentId: number) => `/comment/parent/${parentId}/permissions`,
    stageCommentPermissions: (ideaId: number) => `/stage-comment/idea/${ideaId}/permissions`,
    commentCreate: () => `/comment/create`,
    createStageComment: () => `/stage-comment/create`,
    commentModify: (commentId: number) => `/comment/${commentId}/save`,
    deleteCommentAttachment: (commentId: number, fileId: number) => `/comment/${commentId}/file/${fileId}/delete`,
    deleteStageCommentAttachment: (fileId: number) => `/stage-comment/file/${fileId}/delete`,
    commentLabels: (ideaId: number) => `comment/idea/${ideaId}/labels`,
    commentLabelsById: (commentId: number) => `comment/${commentId}/labels`,
    campaignWorkFlow: (campaignId: number) => `/campaign/${campaignId}/workflow`,
    ideaStages: (ideaId: number) => `/idea/${ideaId}/idea-stages`,
    commentAttachments: (commentId: number) => `comment/${commentId}/add-attachments`,
    deleteComment: (commentId: number) => `comment/${commentId}/delete`,
    deleteStageComment: (commentId: number) => `stage-comment/${commentId}/delete`,
    sidebarLeaderboardItem: (campaignId?: number) => `/sidebar/leaderboard${campaignId ? `/campaign/${campaignId}` : ''}`,
    pinComment: (commentId: number) => `/comment/${commentId}/pin`,
    unpinComment: (commentId: number) => `/comment/${commentId}/unpin`,
    createSuperComment: (commentId: number, commentTypeId: number) => `/comment/${commentId}/commentType/${commentTypeId}/create-super-comment`,
    addCommentsLabel: (commentId: number, labelId: number) => `/comment/${commentId}/label/${labelId}/add`,
    removeCommentsLabel: (commentId: number, labelId: number) => `/comment/${commentId}/label/${labelId}/remove`,
    commentUpDownVote: (commentId: number) => `/comment/${commentId}/vote`,
    commentRetractVote: (commentId: number) => `/comment/${commentId}/vote/retract`,
    leaderboard: (timePeriod?: {
        mode: TimePeriod,
        unit: number
    }) => `/leaderboard${timePeriod ? `/${timePeriod.mode}/${timePeriod.unit}` : ''}`,
    leaderboardCampaigns: () => '/leaderboard/campaigns',
    leaderboardGroups: () => '/leaderboard/groups',
    commentApprove: (commentId: number) => `/comment/${commentId}/approve`,
    commentReject: (commentId: number) => `/comment/${commentId}/reject`,
    commentAbuse: (commentId: number) => `/comment/${commentId}/abuse`,
    giveCommentKudo: (commentId: number) => `/comment/${commentId}/give-kudo`,
    getCommentKudoGivers: (commentId: number) => `/comment/${commentId}/kudo-givers`,
    ideaKudoGivers: (ideaId: number) => `/idea/${ideaId}/kudo-givers`,
    getOriginalComment: (commentId: number) => `/comment/${commentId}/get-original`,
    getEditComment: (commentId: number) => `/comment/${commentId}/edit`,
    footer: () => '/footer',
    confirmCookieConsent: () => '/confirm-cookie-consent',
    addStageCommentAttachment: (commentId: number) => `/stage-comment/${commentId}/add-attachments`,
    searchMembers: () => '/advanced-search/members',
    searchLabels: () => '/labels',
    searchStages: () => '/advanced-search/stages',
    advancedSearchCampaigns: () => '/advanced-search/campaigns',
    advancedSearchIdeas: () => '/advanced-search/ideas',
    searchIdeasCustomFields: () => '/advanced-search/idea-custom-fields',
    dependantCustomSearchFields: (fieldId: number) => `/advanced-search/idea-custom-field/${fieldId}`,
    sudoAdmin: () => '/superuser/sudo',
    deSudoAdmin: () => '/superuser/desudo',
    advanceSearchProfileFields: () => '/advanced-search/profile-fields',
    dependantProfileFields: (fieldId: number) => `/advanced-search/profile-field/${fieldId}`,
    memberIncompleteProfileFields: () => 'profile/incomplete-required-fields',
    memberPendingVerifiableProfileFields: () => 'profile/pending-verifiable-fields',
    memberIncompleteVerifiableProfileFields: () => 'profile/incomplete-verifiable-fields',
    advancedSearchTags: () => `/advanced-search/tags`,
    advancedSearchModeratorTags: () => `/advanced-search/moderator-tags`,
    memberBio: () => `/member/edit-bio`,
    saveMemberBio: () => `/member/save-bio`,
    communityWidget: () => `/sidebar/community-widget`,
    campaignWidget: (campaignId: number) => `/sidebar/campaign/${campaignId}/campaign-widget`,
    sidebarContactWidget: () => '/sidebar/contact-widget',
    emailIdeaContent: (ideaId: number) => `idea/${ideaId}/email-idea-content`,
    authorEmailIdeaContent: (ideaId: number) => `idea/${ideaId}/author-email-idea-content`,
    enablePrivilege: (memberId: number) => `/member/${memberId}/enable-privilege-state`,
    disablePrivilege: (memberId: number) => `/member/${memberId}/disable-privilege-state`,
    ideaTeamMembers: (ideaId: number) => `/idea/${ideaId}/build-team-members`,
    getFeatureTour: () => `/feature-tours/`,
    dismissFeatureTour: (key: string, stepId: number) => `/feature-tours/${key}/${stepId}`,
    skipFeatureTour: (key: string) => `/feature-tours/${key}/skip`,
    fetchJoinCommunityFields: () => `/join-community`,
    joinCommunity: () => `/join-community`,
    fetchJoinCommunityProfileFields: () => `/name-and-profile-fields`,
    saveJoinCommunityProfileFields: () => `/name-and-profile-fields`,
    releasedFeatures: (page: number) => `/released-features/page/${page}`,
    upcomingFeatures: () => `/upcoming-features`,
    googleAnalyticsDimensions: () => `/google-analytics/dimensions`,
    chatConfig: () => `/community/chat-config`,
    sendIdeaMessages: (ideaId: number) => `/idea/${ideaId}/share-via-message`,
    monthlyModeratorDigest: (year: string, month: string) => `/in-app-notifications/campaign-performances/monthly/${year}/${month}`,
    weeklyModeratorDigest: (year: string, weekOfYear: string) => `/in-app-notifications/campaign-performances/weekly/${year}/${weekOfYear}`,
    sendDigestMessages: (campaignId: number) => `/in-app-notifications/campaign-performance/${campaignId}/share-via-message`,
    campaignPerformanceMonthlyModeratorDigest: (campaignId: number, memberId: number, year: string, month: string) => `/in-app-notifications/campaign-performance/${campaignId}/member/${memberId}/monthly/${year}/${month}`,
    campaignPerformanceWeeklyModeratorDigest: (campaignId: number, memberId: number, year: string, weekOfYear: string) => `/in-app-notifications/campaign-performance/${campaignId}/member/${memberId}/weekly/${year}/${weekOfYear}`,
    campaignPerformanceSharingMedia: (campaignId: number) => `/in-app-notifications/campaign-performance/${campaignId}/sharing-media`,
    changeThemePreference: (theme: Theme) => `/change-app-theme/${theme}`,
    landingPage: () => `/landing-page`,
    pageById: (pageId: number) => `/page/${pageId}`,
    pageByIdAndVersion: (pageId: number, version?: number) => `/page/${pageId}/version/${version}`,
    pageByLanguageAndVersion: (pageId: number, languageId: number, version: number) => `/page/${pageId}/version/${version}/language/${languageId}`,
    publishLandingPage: (pageId: number, version?: number) => `/page/${pageId}${version !== undefined ? `/version/${version}` : ''}/publish`,
    landingPageReorderRow: (pageId: number) => `/page/${pageId}/reorder-row`,
    pageVersions: (pageId: number) => `/page/${pageId}/versions`,
    landingPageList: () => `page/list`,
    editVersion: (pageId: number, version: number) => `/page/${pageId}/version/${version}`,
    deleteVersion: (pageId: number, version: number) => `/page/${pageId}/version/${version}/delete`,
    editLandingPage: (pageId: number) => `/page/${pageId}/edit`,
    editLandingPageByVersion: (pageId: number, version = 0) => `/page/${pageId}/version/${version}/edit`,
    cancelEditing: (pageId: number) => `/page/${pageId}/cancel-edit`,
    doneEditing: (pageId: number) => `/page/${pageId}/save`,
    saveLandingPageComponent: () => `/page/component`,
    landingPageUpload: () => `/page/upload`,
    landingPageDownloadFileLink: () => `/page/file-url`,
    landingPageCommunityStatistics: () => `/statistics`,
    landingPageActivitySummaries: (timePeriodGroup: string, timePeriodUnit: number) => `/activity-summary/${timePeriodGroup}/${timePeriodUnit}`,
    landingPageIdeas: (mode: IdeaListMode | 'unspecified' = 'unspecified', order: OrderBy = OrderBy.DESC) => `/ideas/${mode}/${order}`,
    landingPagePinnedIdeas: () => `/ideas/pinned`,
    translatedContent: () => `/get`,
    saveTranslatedContent: () => `/save`,
    inviteFriends: () => `/invite-friends`,
    topbarClassification: () => `/topbar-classification`,
    classifications: (campaignId?: number) => campaignId ? `/classifications/${campaignId}` : `/classifications`,
    effectiveClassifications: () => `/get-effective-classifications`,
    fetchProfileFieldModalStatus: () => `/profile-field-modal`,
    membershipTos: () => `/membership/tos`,
    aiTextAssistance: () => `/get-ai-chat-response`,
    aiImageAssistance: () => `/generate-ai-image`,
    aiIdeaDescription: () => `/generate-ai-assisted-idea-description`,
    communityHomeConfig: () => `/community-home-config`,
    campaignHomeConfig: (campaignId: number) => `/campaign-home-config/${campaignId}`,
    executeCommunityHomeConfig: () => `/community-home/execute`,
    executeCampaignHomeConfig: (campaignId: number) => `/campaign-home/${campaignId}/execute`,
    communityLibraryImages: () => `/community/library-images`,
    campaignLibraryImages: () => `/campaign/library-images`,
    campaignFeaturedGalleryImages: () => `/campaign/featured-images`,
    communityUploadLibraryFiles: () => `/community/upload-files`,
    campaignUploadLibraryFiles: () => `/campaign/upload-files`,
    communityBannerImages: () => `/community/banner-images`,
    campaignBannerImages: () => `/campaign/banner-images`,
    atMentionIdeaCustomField: () => '/member/search/at-mention/idea-custom-field',
    atMentionRefineCustomField: () => '/member/search/at-mention/stage-custom-field',
    cookieConsent: () => `/cookie-consent`,
};