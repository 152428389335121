import {useCallback, useRef} from 'react';
import {useInfiniteQuery} from 'react-query';
import axios, {CancelTokenSource} from 'axios';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {useCommunityService} from './useService';
import {useLocalizer} from './useLocalizer';
import {PagedIdeas} from 'contexts/IdeasContext';
import {DEFAULT_STALE_TIME} from 'constants/AppConstants';
import {IdeaSearchParameters} from 'models/types/IdeaSearchParameters';

export const useIdeaListInfiniteScroll = (ideaListQueryKey: any, fetchEnabled: boolean = true) => {
    const communityService = useCommunityService();
    const localizer = useLocalizer();
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);

    const getIdeas = useCallback(async ({pageParam = 0, queryKey, signal}: any) => {
        cancelTokenRef.current = axios.CancelToken.source();
        signal?.addEventListener('abort', () => {
            cancelTokenRef.current?.cancel();
        });

        if (communityService !== null) {
            try {
                const limit = 7;
                const {campaignId, stageKey, mode, tag, order, moderatorTag, label, myIdeasMode} = queryKey[1];
                const searchParams: IdeaSearchParameters = {
                    tag: tag || '',
                    moderatorTag: moderatorTag || '',
                    applicableIdeasOnly: false,
                    labelKey: label || '',
                    pageParameters: {page: pageParam, limit}
                };
                const pagedResponse = await communityService.fetchIdeas(campaignId, stageKey, mode, order, searchParams, myIdeasMode, cancelTokenRef.current?.token);
                return {
                    data: pagedResponse.content,
                    totalIdeas: pagedResponse.totalElements,
                    allPages: Math.ceil(pagedResponse.totalElements / pagedResponse.pageSize),
                    nextPage: pagedResponse.hasMore ? pagedResponse.pageNo + 1 : undefined,
                    prevPage: pagedResponse.pageNo > 0 ? pagedResponse.pageNo - 1 : undefined
                } as PagedIdeas;
            } catch (e: any) {
                handleErrorResponse(e);
            }
        }
    }, [communityService, handleErrorResponse]);

    return useInfiniteQuery<PagedIdeas | undefined>(ideaListQueryKey, getIdeas, {
        enabled: fetchEnabled,
        cacheTime: Infinity,
        staleTime: DEFAULT_STALE_TIME,
        getNextPageParam: (lastPage) => lastPage?.nextPage,
        getPreviousPageParam: (firstPage) => firstPage?.prevPage
    });
};