import React, {Fragment, lazy, Suspense, useCallback, useEffect} from 'react';
import {AppLoading, AppThemeUtil} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/dist/assets/is-icon-defs.svg';
import {AlertContainer} from '@ideascale/commons/dist/components/alert/AlertContainer';
import {Favicon} from '@ideascale/commons/dist/components/Favicon';
import {ViewPortMetaData} from '@ideascale/commons/dist/components/ViewPortMetaData';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {
    removeBodyTagClass,
    removeHtmlTagClass,
    toggleBodyTagClass,
    toggleHtmlTagClass
} from '@ideascale/commons/dist/utils/CommonUtil';
import {AlertEvent} from '@ideascale/commons/dist/utils/AlertEvent';
import {useUrlQuery} from '@ideascale/commons/dist/hooks/useUrlQuery';
import {useCommunityTosRedirect} from '@ideascale/commons/dist/hooks/useCommunityTosRedirect';
import {useAppContext} from 'contexts/AppContext';
import {EditModeContextProvider} from 'contexts/EditModeContext';
import {useLocalizer} from 'hooks/useLocalizer';
import {Scroller} from 'utils/Scroller';
import {SCROLL_EVENTS} from 'constants/AppConstants';
import {AppContainer} from 'containers/AppContainer';
import {NoticeBanner} from 'components/notice-banner/NoticeBanner';
import {ROUTES} from 'shared/Routes';
import {CommunityConfig} from 'models/CommunityConfig';

const AppEditModeContainer = lazy(() => import('containers/AppEditModeContainer').then(module => ({default: module.AppEditModeContainer})));
const ErrorPage = lazy(() => import('@ideascale/commons/dist/components/errors/ErrorPage').then(module => ({default: module.ErrorPage})));
const PermissionDeniedModal = lazy(() => import('@ideascale/commons/dist/components/PermissionDeniedModal').then(module => ({default: module.PermissionDeniedModal})));

export const App = () => {
    const {
        authentication,
        communityConfig,
        editModeEnabled,
        toggleEditModeEnabled,
        toggleDarkMode,
        pageError,
        setShowPermissionDeniedModal,
        showPermissionDeniedModal,
    } = useAppContext();
    const query = useUrlQuery();
    const localizer = useLocalizer();

    useCommunityTosRedirect({authentication});

    const onCloseAlert = useCallback(() => {
        eventDispatcher.dispatch(AlertEvent.REMOVE_ALERT);
    }, []);

    useEffect(() => {
        eventDispatcher.addListener(SCROLL_EVENTS.SCROLL_TOP, () => {
            Scroller.scrollToTop();
        });

        return () => {
            eventDispatcher.removeListener(SCROLL_EVENTS.SCROLL_TOP);
        };
    }, []);

    useEffect(() => {
        if (query.has(ROUTES.SEARCH_QUERY.EDIT_MODE) && (communityConfig.hasAdminEditPermissions() || communityConfig.hasTranslationPermission())) {
            toggleEditModeEnabled(true);
        }
        return () => {
            editModeEnabled && toggleEditModeEnabled(false);
        };
    }, [communityConfig, editModeEnabled, query, toggleEditModeEnabled]);

    useEffect(() => {
        if (editModeEnabled) {
            toggleBodyTagClass('edit-mode-enabled');
        }
        return () => {
            removeBodyTagClass('edit-mode-enabled');
        };
    }, [editModeEnabled]);

    useEffect(() => {
        if (communityConfig.classificationEnabled) {
            toggleHtmlTagClass('classification-enabled');
        }
        return () => {
            removeHtmlTagClass('classification-enabled');
        };
    }, [communityConfig.classificationEnabled]);

    useEffect(() => {
        const dark = AppThemeUtil.isDarkThemePreferred(authentication.actor.appTheme, authentication.actor.isAnonymous());
        toggleDarkMode(dark);
    }, [authentication.actor, toggleDarkMode]);

    return (
        <Fragment>
            <Favicon faviconImageUrl={communityConfig.faviconImageUrl}/>
            <ViewPortMetaData/>
            <NoticeBanner/>
            {
                (authentication.isEmpty() || communityConfig.id === CommunityConfig.EMPTY.id)
                    ? <Fragment>
                        <AlertContainer svgIconsPath={svgIconsPath} containerClass="top-0"/>
                        {
                            pageError
                                ? <Suspense><ErrorPage localizer={localizer} error={pageError}/></Suspense>
                                : <AppLoading/>
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <AlertContainer svgIconsPath={svgIconsPath} containerClass="position-fixed"
                                        onClose={onCloseAlert}/>
                        {
                            editModeEnabled
                                ?
                                <EditModeContextProvider>
                                    <Suspense fallback={<AppLoading/>}><AppEditModeContainer/></Suspense>
                                </EditModeContextProvider>
                                :
                                <Fragment>
                                    {
                                        !query.has(ROUTES.SEARCH_QUERY.EDIT_MODE) &&
                                        <AppContainer/>
                                    }
                                </Fragment>
                        }
                    </Fragment>
            }
            {
                showPermissionDeniedModal &&
                <Suspense>
                    <PermissionDeniedModal open={showPermissionDeniedModal}
                                           toggle={setShowPermissionDeniedModal}
                                           descriptionText={localizer.msg('frontend-shared.permission-denied-modal.description')}
                                           buttonText={localizer.msg('frontend-shared.permission-denied-modal.button-text')}
                                           titleText={localizer.msg('frontend-shared.permission-denied-modal.title')}/>
                </Suspense>
            }
        </Fragment>
    );
};
