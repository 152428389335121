import React, {Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {Settings} from 'luxon';
import {AppThemeUtil} from '@ideascale/ui';
import {AlertEvent, AlertType, buildAlertEventData} from '@ideascale/commons/dist/utils/AlertEvent';
import {ApiResponseError, AxiosEvent} from '@ideascale/commons/dist/utils/AxiosFactory';
import {emptyErrorHandler} from '@ideascale/commons/dist/utils/Functions';
import {eventDispatcher} from '@ideascale/commons/dist/utils/EventDispatcher';
import {LocalizationMode} from '@ideascale/commons/dist/shared/localization/LocalizationMode';
import {LocalizerImpl} from '@ideascale/commons/dist/shared/localization/LocalizerImpl';
import {LocalizationUtil} from '@ideascale/commons/dist/utils/LocalizationUtil';
import {useAxiosErrorEventHandler} from '@ideascale/commons/dist/hooks/useAxiosErrorEventHandler';
import {useApiErrorResponseHandler} from '@ideascale/commons/dist/hooks/useApiErrorResponseHandler';
import {postLoginRequest} from '@ideascale/commons/dist/utils/CommonUtil';
import {SHOW_COMMUNITY_TOS_ACCEPTANCE_PROMPT} from '@ideascale/commons/dist/shared/AppConstants';
import {Campaign} from '@ideascale/commons/dist/models/Campaign';
import {Authentication} from '@ideascale/commons/dist/models/Authentication';
import {CookieConsent} from '@ideascale/commons/dist/models/CookieConsent';
import {ErrorReason} from '@ideascale/commons/dist/models/enums/ErrorReason';
import {PageError} from '@ideascale/commons/dist/models/PageError';
import {createAppService, createAuthService} from 'hooks/useService';
import {appLinks} from 'services/AppLinks';
import {serviceLinks} from 'services/ServiceLinks';
import {EDIT_MODE_EVENTS, LOCALIZATION_KEY_PREFIX,} from 'constants/AppConstants';
import {ROUTES} from 'shared/Routes';
import {CommunityConfig} from 'models/CommunityConfig';
import {RequireAtLeastOne} from 'utils/TypeUtil';
import {Stages} from 'models/StageDetails';
import {IdeaListViewMode} from 'models/enums/IdeaListViewMode';
import {IdeaDetail} from 'models/IdeaDetail';

type AppContext = {
    authentication: Authentication;
    communityConfig: CommunityConfig;
    currentCampaign: Campaign | null;
    setCurrentCampaign: (campaign: Campaign | null) => void;
    currentStage: Stages | null;
    setCurrentStage: (stages: Stages | null) => void;
    getCurrentCampaignId: () => number | null;
    lastSubmittedIdea: IdeaDetail | null;
    setLastSubmittedIdea: (idea: IdeaDetail | null) => void;
    setIdeaListViewMode: (viewMode: IdeaListViewMode) => void;
    cookieConsent: CookieConsent;
    setCookieConsent: Dispatch<SetStateAction<CookieConsent>>;
    showCookieModal: boolean,
    setShowCookieModal: Dispatch<SetStateAction<boolean>>;
    localizationMode: LocalizationMode;
    setLocalizationMode: (localizationMode: LocalizationMode) => void;
    landingPagePublished: boolean;
    setLandingPagePublished: (nextValue: boolean) => void;
    darkMode: boolean;
    toggleDarkMode: (dark: boolean) => void;
    editModeEnabled: boolean,
    toggleEditModeEnabled: (nextValue: boolean) => void;
    updateCurrentCampaign: (data: RequireAtLeastOne<Campaign>) => void;
    pageError: PageError | null;
    showPermissionDeniedModal: boolean;
    setShowPermissionDeniedModal: Dispatch<SetStateAction<boolean>>;
};

const defaultAppContext: AppContext = {
    authentication: Authentication.EMPTY,
    communityConfig: CommunityConfig.EMPTY,
    currentCampaign: null,
    setCurrentCampaign: () => console.error('setCurrentCampaign not initialized'),
    currentStage: null,
    setCurrentStage: () => console.error('setCurrentStage not initialized'),
    getCurrentCampaignId: () => null,
    lastSubmittedIdea: null,
    setLastSubmittedIdea: () => console.error('setLastSubmittedIdea not initialized'),
    setIdeaListViewMode: () => null,
    cookieConsent: CookieConsent.EMPTY,
    setCookieConsent: () => null,
    showCookieModal: false,
    setShowCookieModal: () => null,
    localizationMode: LocalizationMode.DEFAULT,
    setLocalizationMode: () => console.log('setLocalizationMode is not initialized'),
    landingPagePublished: false,
    setLandingPagePublished: () => null,
    darkMode: false,
    toggleDarkMode: () => null,
    editModeEnabled: false,
    toggleEditModeEnabled: () => null,
    updateCurrentCampaign: () => null,
    pageError: null,
    showPermissionDeniedModal: false,
    setShowPermissionDeniedModal: () => null
};

const ctx = React.createContext<AppContext>(defaultAppContext);

export const useAppContext = () => {
    return React.useContext<AppContext>(ctx);
};

type AppContextProps = {
    children: React.ReactNode;
};

const authService = createAuthService();

export const AppContextProvider = ({children}: AppContextProps) => {
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();
    const [localizationMode, setLocalizationModeInternal] = useState<LocalizationMode>(LocalizationMode.DEFAULT);
    const [isFetchingAuth, setIsFetchingAuth] = useState<boolean>(false);
    const [authentication, setAuthentication] = useState<Authentication>(Authentication.EMPTY);
    const [communityConfig, setCommunityConfig] = useState<CommunityConfig>(CommunityConfig.EMPTY);
    const [cookieConsent, setCookieConsent] = useState<CookieConsent>(CookieConsent.EMPTY);
    const [showCookieModal, setShowCookieModal] = useState(false);
    const [currentCampaign, addCurrentCampaign] = useState<Campaign | null>(null);
    const [lastSubmittedIdea, setLastSubmittedIdea] = useState<IdeaDetail | null>(null);
    const [currentStage, setCurrentStage] = useState<Stages | null>(null);
    const [landingPagePublished, setLandingPagePublished] = useState(false);
    const [pageError, setPageError] = useState<PageError | null>(null);
    const [darkMode, setDarkMode] = useState(false);
    const [editMode, toggleEditMode] = useState(false);
    const [showPermissionDeniedModal, setShowPermissionDeniedModal] = useState(false);
    const {pathname} = useLocation();
    const localizer = useMemo(() => new LocalizerImpl(t, communityConfig.dateTimeFormat, localizationMode, LOCALIZATION_KEY_PREFIX), [communityConfig.dateTimeFormat, localizationMode, t]);
    const {handleErrorResponse} = useApiErrorResponseHandler({localizer});

    useAxiosErrorEventHandler({
        localizer,
        authentication,
        unauthorizedCustomResponseHandler: (eventData: any) => {
            if (authentication.community.isNotPublicCommunity() && authentication.isJoinCommunityAvailable()) {
                navigate(appLinks.joinCommunity(), {replace: true});
            } else {
                const loginUrl = eventData?.community?.loginUrl || authentication.community.loginUrl;
                postLoginRequest({url: loginUrl});
            }
        },
        forbiddenResponseHandler: {
            campaignTosHandler: (eventData: any) => {
                eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn,
                    <Trans i18nKey={'page.campaign-terms-of-service.must-agree'}>
                        <Link
                            to={{pathname: appLinks.campaignTos(String(eventData.error.response.data.campaignId))}}
                            state={{fromUrl: pathname}}
                            onClick={() => eventDispatcher.dispatch(AlertEvent.REMOVE_ALERT, AlertType.warn)}/>
                    </Trans>));
            },
            communityTosHandler: () => {
                const tosAcceptanceExpired = !authentication.isCommunityTosAcceptanceRequired();
                if (pathname !== ROUTES.MEMBERSHIP_TOS && tosAcceptanceExpired) {
                    eventDispatcher.dispatch(SHOW_COMMUNITY_TOS_ACCEPTANCE_PROMPT);
                }
            },
            campaignPermissionDeniedHandler: () => {
                setShowPermissionDeniedModal(true);
            }
        },
        notFoundResponseHandler: {
            handlers: {
                [ErrorReason.COMMUNITY_NOT_FOUND]: () => {
                    setPageError({code: ApiResponseError.NOT_FOUND, reason: ErrorReason.COMMUNITY_NOT_FOUND});
                },
                [ErrorReason.PAGE_NOT_PUBLISHED]: () => {
                    navigate(appLinks.home(), {replace: true});
                },
                [ErrorReason.IDEA_NOT_FOUND]: () => {
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.error, localizer.msg('frontend-shared.errors.idea_not_found')));
                    navigate(appLinks.home(), {replace: true});
                }
            }
        }
    });

    const setCurrentCampaign = useCallback((campaign: Campaign | null) => {
        addCurrentCampaign(campaign);
    }, []);

    const getCurrentCampaignId = useCallback(() => (currentCampaign ? currentCampaign.id : null), [currentCampaign]);

    const setIdeaListViewMode = useCallback((viewMode: IdeaListViewMode) => {
        setCommunityConfig(prevConfig => {
            return {...prevConfig, ideaListViewMode: viewMode};
        });
    }, []);

    const updateCurrentCampaign = useCallback((data: RequireAtLeastOne<Campaign>) => {
        if (currentCampaign) {
            addCurrentCampaign({...currentCampaign, ...data});
        }
    }, [currentCampaign]);

    const setLocalizationMode = useCallback((localizationMode: LocalizationMode) => {
        if (authentication.isSuperUser()) {
            setLocalizationModeInternal(localizationMode);
        } else if (localizationMode !== LocalizationMode.DEFAULT) {
            setLocalizationModeInternal(LocalizationMode.DEFAULT);
        }
    }, [authentication, setLocalizationModeInternal]);

    const toggleDarkMode = useCallback((dark: boolean) => {
        AppThemeUtil.toggleTheme(dark);
        setDarkMode(dark);
    }, []);

    const fetchAppConfigAndLocalization = useCallback((auth: Authentication) => {
        const appService = createAppService(auth);
        Promise.all([appService.fetchCommunityConfig(), appService.fetchLocalizations(auth.actor.lang), appService.fetchCookieConsent()])
            .then(values => {
                const config = values[0] as CommunityConfig;
                const localizations = values[1] as Map<string, string>;
                const cookieConsent = values[2] as CookieConsent;

                const localizationJson = LocalizationUtil.propertiesToJSON(localizations, LOCALIZATION_KEY_PREFIX);
                i18n.addResourceBundle(auth.actor.lang, 'translation', localizationJson, true, true);
                i18n.changeLanguage(auth.actor.lang).then();

                Settings.defaultLocale = auth.actor.lang;
                Settings.defaultZone = auth.actor.timeZone;

                setLandingPagePublished(auth.community.landingPagePublished);
                setCommunityConfig(config);

                setCookieConsent(cookieConsent);
            }).catch(emptyErrorHandler);
    }, [i18n]);

    useEffect(() => {
        if (authentication.isEmpty() && !isFetchingAuth && !pageError) {
            setIsFetchingAuth(true);
            authService.fetchAuthentication(serviceLinks.token())
                .then(auth => {
                    if (auth.isValid()) {
                        if ((auth.actor.isAnonymous() && auth.community.isNotPublicCommunity())
                            && (auth.community.isNotLandingPagePubliclyAvailable() || auth.community.isNotLandingPagePublished())) {
                            eventDispatcher.dispatch(AxiosEvent.UNAUTHORIZED, auth);
                        } else {
                            fetchAppConfigAndLocalization(auth);
                        }
                        setAuthentication(auth);
                    }
                }).catch((error: any) => {
                if (error?.data?.reason === ErrorReason.IP_RESTRICTED) {
                    setPageError({code: error?.status, reason: error?.data?.reason});
                    eventDispatcher.dispatch(AlertEvent.ALERT, buildAlertEventData(AlertType.warn, localizer.msg(`errors.ip_restricted`)));
                } else {
                    handleErrorResponse(error);
                }
            }).finally(() => {
                setIsFetchingAuth(false);
            });
        }
    }, [authentication, fetchAppConfigAndLocalization, handleErrorResponse, i18n, localizer, isFetchingAuth, pageError]);

    useEffect(() => {
        eventDispatcher.addListener(EDIT_MODE_EVENTS.PUBLISHED, () => {
            fetchAppConfigAndLocalization(authentication);
        });
        return () => {
            eventDispatcher.removeListener(EDIT_MODE_EVENTS.PUBLISHED);
        };
    }, [authentication, fetchAppConfigAndLocalization]);

    const contextValue = useMemo(() => {
        return {
            authentication: authentication,
            communityConfig: communityConfig,
            showPermissionDeniedModal,
            setShowPermissionDeniedModal,
            currentCampaign,
            setCurrentCampaign,
            currentStage,
            setCurrentStage,
            getCurrentCampaignId,
            lastSubmittedIdea,
            setLastSubmittedIdea,
            setIdeaListViewMode,
            cookieConsent,
            setCookieConsent,
            showCookieModal,
            setShowCookieModal,
            localizationMode,
            setLocalizationMode,
            landingPagePublished,
            setLandingPagePublished,
            darkMode,
            toggleDarkMode,
            editModeEnabled: editMode,
            toggleEditModeEnabled: toggleEditMode,
            updateCurrentCampaign,
            pageError
        };
    }, [authentication, communityConfig, cookieConsent, currentCampaign, currentStage, darkMode, editMode, getCurrentCampaignId, landingPagePublished, lastSubmittedIdea, localizationMode, pageError, setCurrentCampaign, setIdeaListViewMode, setLocalizationMode, showCookieModal, showPermissionDeniedModal, toggleDarkMode, updateCurrentCampaign]);

    return (
        <ctx.Provider value={contextValue}>
            {children}
        </ctx.Provider>
    );
};

