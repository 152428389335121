import React, {Fragment, useEffect, useMemo, lazy, Suspense} from 'react';
import {Button} from '@ideascale/ui';
import {
    ClassificationAttachmentAttribute
} from '@ideascale/commons/dist/models/classification/ClassificationAttachmentAttribute';
import {FileAttachment} from '@ideascale/commons/dist/models/FileAttachment';
import {HtmlUtils} from '@ideascale/commons/dist/utils/HtmlUtils';
import {Localizer} from '@ideascale/commons/dist/shared/localization/Localizer';
import {useToggle} from '@ideascale/commons/dist/hooks/useToggle';
import {HtmlRenderer} from '../shared/HtmlRenderer';
import {HTML_TO_REACT_CONFIG} from 'constants/AppConstants';

const IdeaContentAttachment = lazy(() => import('./IdeaContentAttachment').then(module => ({default: module.IdeaContentAttachment})));

type IdeaContentProps = {
    localizer: Localizer;
    ideaId: number;
    description: string;
    attachments: FileAttachment[];
    customDescriptionLabel?: string;
    listPage?: boolean;
    classification?: ClassificationAttachmentAttribute[]
}

const SUMMARY_LENGTH = 300;

export const IdeaContent = (props: IdeaContentProps) => {
    const {
        localizer,
        ideaId,
        description,
        attachments,
        customDescriptionLabel,
        listPage = true,
        classification
    } = props;

    const summary = useMemo(() => {
        const descriptionLength = HtmlUtils.getHtmlTextLength(description);
        return descriptionLength > SUMMARY_LENGTH ? HtmlUtils.truncateHtml(description, SUMMARY_LENGTH, '...') : null;
    }, [description]);

    const [showSummary, toggleShowSummary] = useToggle(!!summary);

    const hasPreviewableImageAttachment = () => {
        return attachments?.find(attachment => FileAttachment.isPreviewableImg(attachment.filename));
    };

    useEffect(() => {
        toggleShowSummary(!!summary);
    }, [summary, toggleShowSummary]);

    return (
        <div className={`idea-content mb-3 ${hasPreviewableImageAttachment() ? 'has-image-attachment clearfix' : ''}`}
             id={`idea-${ideaId}-attachments-container`}>
            {
                attachments?.length > 0 &&
                <Suspense>
                    <IdeaContentAttachment attachments={attachments} ideaId={ideaId} localizer={localizer}
                                           popoverContainerId={`idea-${ideaId}-attachments-container`}
                                           classification={classification}/>
                </Suspense>
            }
            {
                customDescriptionLabel &&
                <span className="fw-bold mb-2">{customDescriptionLabel}</span>
            }
            <Fragment>
                <h3 className="sr-only">{localizer.msg('common.idea-description')}</h3>
                <HtmlRenderer content={(showSummary && listPage && summary) ? summary : description}
                              reactOptions={HTML_TO_REACT_CONFIG}/>
            </Fragment>
            {
                showSummary && listPage && summary &&
                <Button color="link" className="p-0 fw-normal d-inline-block"
                        aria-label="Read more idea description" data-test-element-id="read-more-description"
                        onClick={toggleShowSummary}>
                    {localizer.msg('common.read-more')}
                </Button>
            }
        </div>
    );
};
