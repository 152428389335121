import React, {lazy, Suspense} from 'react';
import {Modal} from '@ideascale/ui';
import {NotificationType} from 'models/enums/NotificationType';
import {useDigestService} from 'hooks/useDigestService';

const DigestContent = lazy(() => import('components/DigestContent').then(module => ({default: module.DigestContent})));

type DigestModalContainerProps = {
    showDigestNotificationModal: boolean;
    toggleDigestNotificationModal?: () => void;
    digestNotificationData: {
        year: string,
        notificationType: NotificationType,
        month: string,
        week: string,
        campaignId?: number,
        memberId?: number
    }
}

export const DigestModal = (props: DigestModalContainerProps) => {
    const {showDigestNotificationModal, toggleDigestNotificationModal, digestNotificationData} = props;
    const {
        fetchMonthlyModeratorDigest, fetchWeeklyModeratorDigest,
        fetchCampaignPerformanceWeeklyModeratorDigest, fetchCampaignPerformanceMonthlyModeratorDigest
    } = useDigestService();

    return (
        <Modal isOpen={showDigestNotificationModal}
               toggle={toggleDigestNotificationModal} modalHeaderId="digest-header"
               className="modal-lg" modalBodyClassName="pb-4 pt-0 pe-0">
            <Suspense>
                <DigestContent
                    digestNotificationData={digestNotificationData}
                    fetchMonthlyModeratorDigest={fetchMonthlyModeratorDigest}
                    fetchCampaignPerformanceMonthlyModeratorDigest={fetchCampaignPerformanceMonthlyModeratorDigest}
                    fetchCampaignPerformanceWeeklyModeratorDigest={fetchCampaignPerformanceWeeklyModeratorDigest}
                    fetchWeeklyModeratorDigest={fetchWeeklyModeratorDigest}/>
            </Suspense>
        </Modal>
    );
};