import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Alert as BootstrapAlert} from 'reactstrap';
import {Trans} from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import {Icon} from '@ideascale/ui';
import svgIconsPath from '@ideascale/ui/src/assets/fonts/is-icon-defs.svg';
import {useLocalizer} from 'hooks/useLocalizer';
import {useAppContext} from 'contexts/AppContext';
import {Instance} from 'models/enums/Instance';
import './NoticeBanner.scss';

function shouldShowNoticeBanner(currentDatePST: Date, dateString: string, endDateString: string) {
    const givenDate = new Date(Date.parse(dateString));
    const dayAfterEndDate = new Date(Date.parse(endDateString + ' 12:00 AM PST') + (24 * 60 * 60 * 1000));

    if (isNaN(givenDate.getTime())) {
        console.error('Invalid date format');
        return false;
    }

    const twoDaysInMillis = 2 * 24 * 60 * 60 * 1000;

    const twoDaysAgoDate = new Date(givenDate.getTime() - twoDaysInMillis);
    return currentDatePST >= twoDaysAgoDate && currentDatePST <= dayAfterEndDate;
}

const REGION_READ_ONLY_TIMES: Record<Instance, { start: string, end: string }[]> = {
    [Instance.US]: [
        {
            start: 'Friday, Sept 27, 2024, 5:00 PM PST',
            end: 'Sunday, Sept 29, 2024'
        },
        {
            start: 'Friday, Oct 4, 2024, 5:00 PM PST',
            end: 'Sunday, Oct 6, 2024'
        }
    ],
    [Instance.AE]: [
        {
            start: 'Friday, Sept 27, 2024, 5:00 PM PST',
            end: 'Sunday, Sept 29, 2024'
        }
    ],
    [Instance.PE]: [
        {
            start: 'Friday, Sept 27, 2024, 5:00 PM PST',
            end: 'Sunday, Sept 29, 2024'
        },
        {
            start: 'Friday, Oct 4, 2024, 5:00 PM PST',
            end: 'Sunday, Oct 6, 2024'
        }
    ],
    [Instance.TEST]: [
        {
            start: 'Friday, Sept 27, 2024, 5:00 PM PST',
            end: 'Sunday, Sept 29, 2024'
        }
    ],
    [Instance.CA]: [
        {
            start: 'Friday, Sept 27, 2024, 5:00 PM PST',
            end: 'Saturday, Sept 29, 2024'
        }
    ],
    [Instance.AU]: [
        {
            start: 'Friday, Oct 18, 2024, 6:00 AM PST',
            end: 'Sunday, Oct 20, 2024'
        }
    ],
    [Instance.EU]: [
        {
            start: 'Friday, Oct 18, 2024, 6:00 AM PST',
            end: 'Sunday, Oct 20, 2024'
        }
    ],
    [Instance.GOV]: [
        {
            start: 'Friday, Oct 25, 2024, 5:00 PM PST',
            end: 'Sunday, Oct 27, 2024'
        }
    ],
};

export const NoticeBanner = () => {
    const {
        communityConfig: {instance, serverTime}
    } = useAppContext();
    const localizer = useLocalizer();

    const shouldShowNextDateForUsaOrPeInstance = useCallback(() => {
        if (isEmpty(serverTime)) {
            return false;
        }
        return (instance === Instance.US || instance === Instance.PE) && new Date(serverTime).getTime() >= new Date('Friday, Sept 30, 2024, 12:00 AM PST').getTime();
    }, [instance, serverTime]);

    const [visible, setVisible] = useState(false);

    const getReadOnlyNoticeDates = useMemo(() => {
        return instance in REGION_READ_ONLY_TIMES ? REGION_READ_ONLY_TIMES[instance] : REGION_READ_ONLY_TIMES[Instance.US];
    }, [instance]);

    useEffect(() => {
        if (!isEmpty(serverTime)) {
            setVisible(shouldShowNoticeBanner(new Date(serverTime), getReadOnlyNoticeDates[shouldShowNextDateForUsaOrPeInstance() ? 1 : 0].start, getReadOnlyNoticeDates[shouldShowNextDateForUsaOrPeInstance() ? 1 : 0].end));
        }
    }, [getReadOnlyNoticeDates, instance, serverTime, shouldShowNextDateForUsaOrPeInstance]);

    return <BootstrapAlert className="notice-banner position-fixed" isOpen={visible} toggle={() => {
        setVisible(false);
    }} closeAriaLabel="Dismiss Migration Notice">
        <div className="notice-content-container">
            <div>
                <Icon iconSpritePath={svgIconsPath} name="exclamation-solid-triangle" width={20} height={20}/>
            </div>
            <div>
                <h2>{localizer.msg('migration-notice.heading')}</h2>
                <p>
                    <Trans
                        i18nKey="migration-notice.notice"
                        context={getReadOnlyNoticeDates[1] != null && !shouldShowNextDateForUsaOrPeInstance() ? 'with-additional-dates' : ''}
                        values={{
                            start: getReadOnlyNoticeDates[shouldShowNextDateForUsaOrPeInstance() ? 1 : 0].start,
                            end: getReadOnlyNoticeDates[shouldShowNextDateForUsaOrPeInstance() ? 1 : 0].end,
                            start2: !shouldShowNextDateForUsaOrPeInstance() && getReadOnlyNoticeDates[1]?.start,
                            end2: !shouldShowNextDateForUsaOrPeInstance() && getReadOnlyNoticeDates[1]?.end
                        }}
                    >
                        <strong/>
                        <a className="fw-normal" href="https://help.ideascale.com/migration-key-information"
                           target="_blank"
                           rel="noreferrer">Link</a>
                    </Trans>
                </p>
            </div>
        </div>
    </BootstrapAlert>;
};